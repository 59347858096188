import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkSpaceState, WorkspaceType } from "../../../types";

const initialState: WorkSpaceState = {
  workspace_invitations: [],
  workspaces: [],
  workspace_members: [],
  workspaceToBeEdited: null,
  currentWorkspace: null,
  openInviteModal: false,
  workspaceModal: false,
  currentTab: null,
  open: false,
};

const workSpaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setWorkspaces: (state, { payload }: PayloadAction<WorkspaceType[]>) => {
      if (payload) {
        state.workspaces = payload;
      }
    },
    setWorkspaceMembers: (state, { payload }: PayloadAction<any[]>) => {
      if (payload) {
        state.workspace_members = payload;
      }
    },
    setWorkspaceInvitations: (state, { payload }: PayloadAction<any>) => {
      if (payload) {
        state.workspace_invitations = payload;
      }
    },
    setCurrentWorkspace: (state, { payload }: PayloadAction<any>) => {
      if (payload) {
        state.currentWorkspace = payload;
      }
    },
    setWorkspace: (state, { payload }: PayloadAction<WorkspaceType>) => {
      if (payload) {
        state.workspaces = [...state.workspaces, payload];
      }
    },
    UpdateWorkspace: (state, { payload }: PayloadAction<WorkspaceType>) => {
      if (payload) {
        state.workspaces = state.workspaces.map((workspace) =>
          workspace.pkid === payload.pkid ? payload : workspace
        );
      }
    },
    DeleteWorkspace: (state, { payload }: PayloadAction<WorkspaceType>) => {
      if (payload) {
        state.workspaces = state.workspaces.filter(
          (workspace) => workspace.pkid !== payload.pkid
        );
      }
    },
    setWorkspaceToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.workspaceToBeEdited = payload;
      }
    },
    setWorkspaceModal: (state, { payload }: PayloadAction<boolean>) => {
      state.workspaceModal = payload;
    },
    setInviteModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openInviteModal = payload;
    },
    setCurrentTab: (state, { payload }) => {
      state.currentTab = payload;
    },
    setOpen: (state, { payload }) => {
      state.open = payload;
    },
    clearCurrentWorkspace: (state) => {
      state.currentWorkspace = null;
    },
    clearWorkspaceState: (state) => {
      state.workspace_invitations = [];
      state.workspace_members = [];
      state.workspaceToBeEdited = null;
      state.currentWorkspace = null;
      state.currentTab = null;
    },
    switchWorkspace: (state, { payload }: PayloadAction<string>) => {
      // Clear current workspace state
      state.workspace_invitations = [];
      state.workspace_members = [];
      state.workspaceToBeEdited = null;
      state.currentTab = null;
      
      // Set new workspace
      state.currentWorkspace = payload;
      
      // Set loading state
      state.open = true;
    },
  },
});

export const {
  setOpen,
  setWorkspace,
  setWorkspaces,
  setCurrentTab,
  setInviteModal,
  DeleteWorkspace,
  UpdateWorkspace,
  setWorkspaceModal,
  setWorkspaceMembers,
  setCurrentWorkspace,
  clearCurrentWorkspace,
  clearWorkspaceState,
  switchWorkspace,
  setWorkspaceToBeEdited,
  setWorkspaceInvitations,
} = workSpaceSlice.actions;

export default workSpaceSlice.reducer;
