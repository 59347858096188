import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  logout,
  setIsAuthenticated,
  setUserData,
} from "../redux/features/auth/authSlice";
import { user } from "../types/auth/model";

export default function useAuth() {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.auth);

  const getUserData = useCallback(
    (userData: user) => {
      dispatch(setUserData(userData));
    },
    [dispatch],
  );

  const authenticate = useCallback(
    (value: boolean) => {
      dispatch(setIsAuthenticated(value));
    },
    [dispatch],
  )
  const signOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return {
    ...state,
    signOut,
    getUserData,
    authenticate,
  };
}
