import React, { useState } from "react";
type Props = {
  label?: string;
  type?: string;
  name?: string;
  value: string;
  styles?: string;
  isHyperlink?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onFocus?: VoidFunction;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  error?: string | null | boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function WorkspaceInput({
  label,
  type,
  name,
  value,
  styles,
  error,
  onFocus,
  onChange,
  iconLeft,
  disabled,
  iconRight,
  onKeyDown,
  placeholder,
  isHyperlink = false,
}: Props) {
  const [focus, setFocus] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div>
      {isHyperlink && isValidUrl(value) && !isEditing ? (
        <div className="flex items-center justify-between">
          <a
            href={value.startsWith("http") ? value : `http://${value}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-sm underline"
            onClick={(e) => e.stopPropagation()}
          >
            {value}
          </a>
          <button onClick={toggleEditMode} className="ml-2 ">
            Edit
          </button>
        </div>
      ) : (
        <div className="flex items-center">
          <input
            type={type}
            name={name}
            onKeyDown={onKeyDown}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`${styles} h-full outline-none rounded border text-[13px]  text-white text-sm px-3 ${focus
                ? " border-stroke ring-stroke"
                : "border-primary_color ring-primary_color"
              }  block w-full`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
          />
          {isHyperlink && isValidUrl(value) && (
            <button onClick={toggleEditMode} className="ml-2 ">
              Done
            </button>
          )}
        </div>
      )}
    </div>
  );
}
